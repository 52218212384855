import React, { useContext } from "react";
import { types, flow } from "mobx-state-tree";
import { useLocalStore } from "mobx-react-lite";
import AppStore from "./AppStore";

export const Domain = types.model({
  domain: types.string,
  is_master: types.boolean
})

const Store = types
  .model({
    appStore: AppStore,
    isAuthorized: types.boolean,
    ssoEnabled: types.maybeNull(types.boolean),
    ssoProviders: types.array(
      types.model({
        sso_code: types.string,
        sso_stateid: types.string,
        sso_type: types.string,
        sso_url: types.string,
      })
    ),
    isLoading: types.boolean,
    errorText: types.string,
    username: types.string,
    domain: types.string,
    domains: types.array(Domain),
  })
  .actions((self) => ({
    setAuthorized: (value: boolean) => {
      self.isAuthorized = value;
      self.isLoading = false;
    },

    checkSSOProviders: flow(function* checkSSOProviders(domain: string) {
      try {
        if (!domain) return false;

        let url = "/api/sso/providers";
        url += `?${new URLSearchParams({ domain })}`;

        const res: any = yield fetch(url, {
          method: "GET",
        });
        const body: any = yield res.json();

        if (body && body.providers) {
          if (body.providers.length === 1) {
            window.location.href = body.providers[0].sso_url;
          }
          self.ssoProviders.replace(body.providers);
          return true;
        }
        return false;
      } catch (err) {
        console.error(err);
        return null;
      }
    }),

    checkSSOSetting: flow(function* checkSSOSetting() {
      try {
        const res: any = yield fetch("/api/sso/enabled", {
          method: "GET",
        });
        if (res && res.status >= 200 && res.status <= 299) {
          const body: any = yield res.json();
          if (body) {
            self.ssoEnabled = body.sso_enabled;
            return body.sso_enabled;
          }
          console.log("checkSSOSetting(): !body -> return true; self.ssoEnabled remains "+self.ssoEnabled);
          return true;
        } else if (res && res.status == 404) {
          console.warn("GET /api/sso/enabled error 404 Not Found. Must be an older platform version. SSO disabled.");
          self.ssoEnabled = false; // pretend /api/sso/enabled is false (older platform versions don't have this endpoint).
          return false;
        } else {
          console.error("GET /api/sso/enabled error ", res.status);
          return null;
        }
      } catch (err) {
        console.error(err);
        return null;
      }
    }),

    setSessionInfo: (value: any) => {
      const username = value["name"];
      const domain = value["domain"];
      const domains = value["domains"];
      self.username = username;
      self.domain = domain;
      self.domains =
        domains !== undefined
          ? domains
              .sort(function (A: any, B: any) {
                if (A.is_master) return -1;
                else if (B.is_master) return 1;
                else return 0;
              })
              .filter((v: any) => {
                return v.domain !== domain;
              })
          : [];

      // to localstorage
      localStorage.setItem("rDomain", domain);
      localStorage.setItem("rSolution", value["solution"]);
      localStorage.setItem("rLogin", value["login"]);
      localStorage.setItem("rUserId", value["user_id"]);
      localStorage.setItem("rUserName", value["name"]);
      localStorage.setItem(
        "rUser",
        JSON.stringify({
          id: value["user_id"],
          domains: domains,
          login: value["login"],
          name: value["name"],
          name_login: value["name_login"],
          roles: value["roles"],
          tags: value["tags"],
        })
      );
    },

    login: flow(function* login(state, clearAllSessions: boolean) {
      yield fetch("/rest/v1/iam/sessions/current", { method: "DELETE" });
      let data: any = {
        domain: state.domain,
        login: state.login,
        pwd: state.pwd,
      };
      if (clearAllSessions) {
        data = { ...data, action: "purge_login" };
      }
      const resp2: any = yield fetch("/rest/v1/iam/sessions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (resp2 && resp2.status >= 200 && resp2.status <= 299) {
        self.isAuthorized = true;
        self.errorText = "";
        // to localstorage
        localStorage.setItem("rDomain", state.domain);
        localStorage.setItem("rLogin", state.login);
        return true;
      } else {
        var body: any = { error_code: "", error_message: "" };
        try {
          body = yield resp2.json();
        } catch {}
        self.isAuthorized = false;
        self.errorText =
          body["error_code"] === undefined
            ? "Unknown error"
            : body["error_code"] + " - " + body["error_message"];
        return false;
      }
    }),

    clearOtherSessions: flow(function* logout() {
      const resp: any = yield fetch("/rest/v1/iam/sessions/others", {
        method: "DELETE",
      });
      return resp && resp.status >= 200 && resp.status <= 299;
    }),

    logout: flow(function* logout() {
      // from localStorage
      localStorage.removeItem("rDomain");
      localStorage.removeItem("rSolution");
      localStorage.removeItem("rLogin");
      localStorage.removeItem("rUserId");
      localStorage.removeItem("rUserName");
      localStorage.removeItem("rUser");

      const resp: any = yield fetch("/rest/v1/iam/sessions/current", {
        method: "DELETE",
      });
      return resp && resp.status >= 200 && resp.status <= 299;
    }),

    changeDomain: flow(function* changeDomain(domain: string) {
      const resp: any = yield fetch("/rest/v1/iam/sessions/current", {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ domain: domain }),
      });

      return resp.status >= 200 && resp.status <= 299;
    }),
  }))
  .views((self) => ({
    getTitle: () => {
      return self.isAuthorized ? "Authorized" : "Unauthorized";
    },
    getAccountName: () => {
      return self.username + " @ " + self.domain;
    },
    getSSOSetting: () => self.ssoEnabled,
    getSSOProviders: () => self.ssoProviders,
  }));

export const StoreContext = React.createContext<typeof Store.Type | undefined>(
  undefined
);

export const useStore = () => useContext(StoreContext);

export const createStore = () =>
  Store.create({
    appStore: {},
    ssoEnabled: null,
    ssoProviders: [],
    isAuthorized: true,
    isLoading: true,
    errorText: "",
    username: "",
    domain: "",
    domains: [],
  });

export const StoreProvider: React.FC = ({ children }) => {
  const store = useLocalStore(createStore);
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
