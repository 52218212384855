import { types } from 'mobx-state-tree';

const AppModel = types.model({
    id: types.string,
    title: types.string,
    hint: types.string,
    imgUrl: types.string,
    appUrl: types.string,
    isIconFA: types.boolean,
    faIcon: types.maybe(types.string)
});

export default AppModel;

