import React from 'react';
import AppTile from '../AppTile';
import {AppListData} from './index';
import styles from "./AppList.module.css";

const AppList: React.FC<AppListData> = ({items}) => {
    const tiles = items.map((value) => {
        return <AppTile imgUrl={value.imgUrl} 
                 title={value.title} 
                 key={value.id} 
                 appUrl={value.appUrl} 
                 hint={value.hint}
                 isIconFA={value.isIconFA}
                 faIcon={value.faIcon}>
        </AppTile>;
        });
    
    return (
        <React.Fragment>
            <div className={styles.applist}>
                {tiles}
            </div>            
        </React.Fragment>
    );
}

export default AppList;