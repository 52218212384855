import React, { useState, useEffect } from 'react';
import { AppData } from './index';
import { Card, CardContent, Typography, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import styles from './AppTile.module.css';
import './x.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(fas);

const useStyles = makeStyles(({ palette }: Theme) => 
    createStyles({
    card: { 
        maxWidth: 160,
        minWidth: 160,
        maxHeight: 500,        
        height: "85%",
        padding: 10,
    },
    cardcontent: {
        fontSize: "17px",
    },
    imageDiv: {        
        height: 70,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',        
        marginTop: 20,        
        // border: '1px solid black'        
    },
    media: {
        objectFit: 'contain',
        width: 140,
        height: 70,
        // border: '1px solid black',
    },
    hint: {
        fontSize: "12px",
    },
    icon: {
        color: palette.secondary.main,
    },
}));

const AppTile: React.FC<AppData> = ({imgUrl,isIconFA,title,hint,appUrl,faIcon}) => {
    const [isOver, setState] = useState(false);
    const classes = useStyles();
    const [icon, setIcon] = useState<any | undefined>(undefined);

    const mouseOverHandler = () => {setState(true)};
    const mouseOutHandler = () => {setState(false)};

    const setFaIcon = (faIconName:string | undefined) => {
        const nameFa:string = faIconName ? faIconName.replace("fa-","") : "window-close";
        const name:IconName = nameFa as IconName;
        if (name) {
            setIcon(<FontAwesomeIcon icon={['fas', name]} size="5x" className={classes.icon}/>);
        } else {
            setIcon(<FontAwesomeIcon icon={['fas', 'window-close']} size="5x" className={classes.icon}/>);
        }
    };

    useEffect(() => {
        if (isIconFA===false && imgUrl) {
            fetch(imgUrl).then(resp => {
                if (resp.status === 200) {
                    //return resp.text();
                    setIcon(<img src={imgUrl} className={classes.media} alt=""/>);
                } else { setFaIcon(faIcon); }
            // }).then(txt => {
            //     if (txt !== '') {
            //         setIcon(<img src={`data:image/png;base64,${btoa(unescape(encodeURIComponent(txt)))}`} className={classes.media} alt=""/>);
            //     }
            }).catch(() => setFaIcon(faIcon));
        } else { setFaIcon(imgUrl); }
    }, [isIconFA]);

    return (
        <div className={styles.container}>
            <a href={appUrl} className={styles.title} onMouseOver={mouseOverHandler} onMouseOut={mouseOutHandler}>
                <Card className={classes.card} raised={isOver}>
                    <div className={classes.imageDiv}>
                        {icon}
                    </div>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h2" className={classes.cardcontent}>
                            {title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.hint}>
                            {hint}
                        </Typography>
                    </CardContent>
                </Card>
            </a>
        </div>
    );
}

export default AppTile;

