import React, { useEffect } from 'react';
import { useStore } from '../../stores';
import { observer } from 'mobx-react-lite';
import AppList from '../../components/AppList';

const AppContainer = observer(() => {
    const store = useStore();

    useEffect(() => {store && store.appStore.getApps()}, [store]);

    return (
        <React.Fragment>
            {store && <AppList items={store.appStore.items.toJS()}></AppList>}
        </React.Fragment>
    );
})

export default AppContainer;