import AppModel from './AppModel';
import { types, Instance, flow, getParent } from 'mobx-state-tree';

const AppItems = types.array(AppModel);

export type IAppItems = Instance<typeof AppItems>;

const AppStore = types.model({
    items: AppItems,
}).views(self => ({
    getApps: () => {         
        return self.items; 
    }
})).actions(self => ({
    fill: flow(function* fill() {
        const store: any = getParent(self);

        const resp: any = yield fetch('/rest/v1/iam/sessions/current');

        if (resp.status >= 300)
        {
            store.setAuthorized(false);
            self.items.clear();
            return false;
        }

        const body: any = yield resp.json();
        if (body['webapps'] === undefined)
        {
            store.setAuthorized(false);
            self.items.clear();
            return false;
        }

        store.setAuthorized(true);
        const apps = body['webapps'].map((item:{name:string, description:string, url:string, order:number, 'fa-icon':string, icon?:string}) => ({
            id: item.name ? item.name : "",
            title: item.name ? item.name : "",
            hint: item.description ? item.description : "",
            imgUrl: item['icon'] ? item['icon'] : item['fa-icon'] ? item['fa-icon'] : '',
            isIconFA: item['icon'] ? false : item['fa-icon'] ? true : false,
            appUrl: item.url ? item.url : "",
            order: item.order ? item.order : 10000,
            faIcon: item['fa-icon'] ? item['fa-icon'] : "fa-window-close"}));
        self.items = apps.sort(function(A:{order:number},B:{order:number}) { return A.order - B.order});

        store.setSessionInfo(body);

        return true;
    })
}));

export default AppStore;